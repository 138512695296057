import { Box, Button, List, ListItem, ListItemText, Typography } from '@mui/material'
import { format } from 'date-fns'
import Image from 'next/image'
import React, { useContext } from 'react'
import { toast } from 'react-toastify'

import { Modal } from '@/components/common/Modal'
import { TextEditor } from '@/components/common/TextEditor'
import { ChallengeList } from '@/components/pages/index/ChallengeList'
import { axiosContext } from '@/context/axios'
import { LessonKarte } from '@/models/LessonKarte'
import { LessonKarteCondition } from '@/models/LessonKarteCondition'
import { Model, estimatePrice } from '@/utils/openai'

const conditionEmoji = (condition: LessonKarteCondition | null) => {
  if (condition === 'good') {
    return '😄'
  } else if (condition === 'normal') {
    return '😐'
  } else if (condition === 'bad') {
    return '🥲'
  } else {
    return ''
  }
}

export const LessonKarteComponent: React.VFC<{
  lessonKarte: LessonKarte
  editable: boolean
  afterSubmit: () => void
}> = ({ lessonKarte, editable, afterSubmit }) => {
  const { axiosInstance } = useContext(axiosContext)
  const [comment, setComment] = React.useState<string | null>(lessonKarte.comment ?? lessonKarte.draftComment)
  const [modalOpen, setModalOpen] = React.useState(false)
  const [fileUrl, setFileUrl] = React.useState<string | null>(null)
  const [files, setFiles] = React.useState<File[]>([])

  const getFilesFromS3 = async (fileKeys: string[]) => {
    const karteFiles: File[] = []
    for (const fileKey of fileKeys) {
      const { data: url } = await axiosInstance.get(`/api/s3/presignedUrlForGetObject/${fileKey}`)
      const { data: file } = await axiosInstance.get(url, { responseType: 'blob' })
      karteFiles.push(file)
    }

    setFiles(karteFiles)
  }

  React.useEffect(() => {
    getFilesFromS3(lessonKarte.fileKeys)
  }, [])

  const handleClose = () => setModalOpen(false)

  const handleSubmit = async () => {
    try {
      await axiosInstance.post('/api/lesson_karte/comment', {
        lessonKarteId: lessonKarte.id,
        comment: comment,
      })
      afterSubmit()
      toast.success('コメントを登録しました')
    } catch (error) {
      toast.error('Server Error')
    }
  }

  const handleOpneImageModal = async (index: number) => {
    setFileUrl(URL.createObjectURL(files[index]))
    setModalOpen(true)
  }

  const isChosedToTakeABreak: boolean = lessonKarte.time === 0

  const model = Model.GPT4_TURBO
  const [aiLoading, setAiLoading] = React.useState(false)
  const [aiEstimate, setAiEstimate] = React.useState<number | null>(null)
  const postChatGpt = async () => {
    setAiLoading(true)
    setAiEstimate(null)
    await axiosInstance
      .get(
        `/api/lesson_karte/auto_comment?child_id=${lessonKarte.childId}&problems=${lessonKarte.problems}&model=${model}`
      )
      .then((response) => {
        const data = response.data
        setComment(response.data.comment)
        setAiEstimate(estimatePrice(data.usage.promptTokens, data.usage.completionTokens, model))
      })
      .catch((error) => {
        toast.error(error.response.data.message)
        setAiLoading(false)
        setAiEstimate(null)
      })

    setAiLoading(false)
  }

  return (
    <Box>
      <Box
        sx={{
          border: '2px solid #37A2C4',
          p: 1,
        }}
      >
        <List>
          <Box>
            <Typography
              variant="body1"
              sx={{
                borderBottom: 1,
                borderColor: '#F5F5F5',
              }}
            >
              ユーザーのカルテ更新日時:{' '}
              {lessonKarte.filledAt ? format(new Date(lessonKarte.filledAt), 'yyyy年MM月dd日 HH時mm分') : '未提出'}
            </Typography>
          </Box>
          {isChosedToTakeABreak && (
            <Box>
              <Typography
                variant="body2"
                sx={{
                  textAlign: 'center',
                  fontSize: '1rem',
                  backgroundColor: '#9c27b0',
                  color: 'white',
                  margin: '1em',
                  padding: '2px',
                }}
              >
                ユーザーがお休みを選択しました
              </Typography>
            </Box>
          )}
          <Box>
            <Typography
              variant="body1"
              sx={{
                color: '#73D0FF',
                borderBottom: 1,
                borderColor: '#F5F5F5',
                fontWeight: 'bold',
                mt: 3,
              }}
            >
              そろばんの目標
            </Typography>
            <ChallengeList tasks={lessonKarte.sorobanChallengeList} />
          </Box>
          <Box>
            <Typography
              variant="body1"
              sx={{
                color: '#FFA13F',
                borderBottom: 1,
                borderColor: '#F5F5F5',
                fontWeight: 'bold',
              }}
            >
              暗算の目標
            </Typography>
            <ChallengeList tasks={lessonKarte.anzanChallengeList} />
          </Box>
          {!isChosedToTakeABreak && (
            <>
              <ListItem divider sx={{ display: 'block' }}>
                <ListItemText primary={'学習時間: ' + (lessonKarte.time ? lessonKarte.time + '分' : 'お休み')} />
              </ListItem>
              <ListItem
                divider
                sx={{
                  display: 'block',
                }}
              >
                <ListItemText primary="進捗" />
                <Box component="p">{lessonKarte.progress ?? '記載なし'}</Box>
              </ListItem>
              <ListItem divider sx={{ display: 'block' }}>
                <ListItemText primary={'子供の様子: ' + conditionEmoji(lessonKarte.condition)} />
                <ListItemText primary={lessonKarte.detailedCondition} />
              </ListItem>
              <ListItem divider sx={{ display: 'block' }}>
                <ListItemText primary={'お母さん／お父さんの様子'} />
                <Box component="p">{lessonKarte.parentCondition}</Box>
              </ListItem>
              <ListItem
                divider
                sx={{
                  display: 'block',
                }}
              >
                <ListItemText primary="困りごと" />
                <Box component="p">{lessonKarte.problems ?? '記載なし'}</Box>
              </ListItem>
              <ListItem
                divider
                sx={{
                  display: 'block',
                }}
              >
                <ListItemText primary="ファイル" />
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  {files.length > 0
                    ? files.map((image, i) => (
                        <Box
                          key={i}
                          style={{
                            position: 'relative',
                            width: '70px',
                            margin: '0 10px',
                          }}
                        >
                          <Button onClick={() => handleOpneImageModal(i)}>
                            <Image src={URL.createObjectURL(image)} alt="アップロードファイル" width="50" height="50" />
                          </Button>
                        </Box>
                      ))
                    : 'なし'}
                </Box>
              </ListItem>
              <ListItem
                divider
                sx={{
                  display: 'block',
                }}
              ></ListItem>
            </>
          )}
          <ListItemText primary="講師からのコメント" />
          {editable ? (
            <>
              {lessonKarte.draftComment && (
                <Typography sx={{ color: 'red' }}>
                  表示されているのはAIによる下書きです。&quot;更新する&quot;ボタンで確定するまで生徒には表示されません。
                </Typography>
              )}
              <TextEditor text={comment} onChange={setComment} />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ display: 'flex', alignItems: 'end' }}>
                  <Button sx={{ mt: 1, mr: 1 }} size="small" variant="contained" onClick={() => postChatGpt()}>
                    {aiLoading && <Typography>生成中...</Typography>}
                    {!aiLoading && <Typography>AIで生成する ({model})</Typography>}
                  </Button>
                  {aiEstimate != null && (
                    <Typography component="span" sx={{ color: 'gray' }}>
                      約{parseFloat(aiEstimate.toFixed(2))}円
                    </Typography>
                  )}
                </Box>
              </Box>
            </>
          ) : (
            <Box component="p">{lessonKarte.comment ?? (lessonKarte.problems ? '未返信' : '記載なし')}</Box>
          )}
        </List>
        {editable && (
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{
              my: 2,
            }}
          >
            更新する
          </Button>
        )}
      </Box>
      <Modal open={modalOpen} title={''} handleClose={handleClose}>
        {fileUrl && <Image src={fileUrl} alt="拡大アップロードファイル" width="600" height="600" />}
      </Modal>
    </Box>
  )
}
