import { Checkbox, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'

import { Challenge } from '@/models/Challenge'

export const ChallengeList = (props: Props) => {
  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {props.tasks.map((value) => {
        const labelId = `checkbox-list-label-${value.id}`

        return (
          <ListItem
            key={value.id}
            disablePadding
            disableGutters
            sx={{
              fontSize: '14px',
              ml: '10px',
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: '30px',
              }}
            >
              <Checkbox
                edge="start"
                checked={value.isDone}
                tabIndex={-1}
                disableRipple
                disabled
                inputProps={{ 'aria-labelledby': labelId }}
                sx={{
                  px: 1,
                  pt: 0,
                }}
              />
            </ListItemIcon>
            <ListItemText
              sx={{
                my: 0,
              }}
              id={labelId}
              primary={`${pageText(value.page)} ${value.customeTitle ? value.customeTitle : value.title}`}
            />
          </ListItem>
        )
      })}
    </List>
  )
}

const pageText = (page: number | null) => {
  return page ? `(p.${page})` : ''
}

type Props = {
  tasks: Array<Challenge>
}
