import { IndividualLessonKarte } from '@/models/IndividualLessonKarte'
import { LessonKarte } from '@/models/LessonKarte'
import { RestKarte } from '@/models/RestKarte'

export type Karte = {
  id: number
  childId: number
  lessonDate: string
  content: LessonKarte | IndividualLessonKarte | RestKarte
  judge: () => string
}

export const isLessonKarte = (karte: Karte): boolean => {
  return karte.content.type === 'lesson_karte'
}

export const isIndividualLessonKarte = (karte: Karte): boolean => {
  return karte.content.type === 'individual_lesson_karte'
}

export const isRestKarte = (karte: Karte): boolean => {
  return karte.content.type === 'rest_karte'
}

export const karteContentSwitcher = (
  karte: Karte,
  lesson: (karte: LessonKarte) => JSX.Element,
  individualLesson: (karte: IndividualLessonKarte) => JSX.Element,
  rest: (karte: RestKarte) => JSX.Element
): JSX.Element => {
  if (isLessonKarte(karte)) {
    return lesson(karte.content as LessonKarte)
  } else if (isIndividualLessonKarte(karte)) {
    return individualLesson(karte.content as IndividualLessonKarte)
  } else if (isRestKarte(karte)) {
    return rest(karte.content as RestKarte)
  }
  throw new Error('Unexpected karte type')
}
