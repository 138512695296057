import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import { IconButton, TextField } from '@mui/material'
import React from 'react'

export const TextEditor: React.VFC<{
  text: string | null
  onChange: (text: string) => void
}> = ({ text, onChange }) => {
  const value = text || ''
  const [emojiPickerOpen, setEmojiPickerOpen] = React.useState(false)

  const inputRef = React.useRef()
  const textLength: number = value.length
  const [selectionStart, setSelectionStart] = React.useState<number | null>(textLength)
  const updateSelectionStart = (event: React.SyntheticEvent<HTMLDivElement, Event>) => {
    const target = event.target as HTMLInputElement
    setSelectionStart(target.selectionStart)
  }

  const insertEmoji = (emoji: string) => {
    const newText = value.substring(0, selectionStart!) + emoji + value.substring(selectionStart!)
    onChange(newText)
    setSelectionStart(selectionStart! + 2)
  }
  return (
    <>
      <TextField
        id="outlined-multiline-flexible"
        fullWidth
        multiline
        rows={4}
        name="comment"
        onSelect={(e) => updateSelectionStart(e)}
        inputRef={inputRef}
        value={value}
        placeholder=""
        onChange={(e) => onChange(e.target.value)}
      />
      {!emojiPickerOpen && (
        <IconButton aria-label="emoji-icon" onClick={() => setEmojiPickerOpen(true)}>
          <SentimentVerySatisfiedIcon />
        </IconButton>
      )}
      {emojiPickerOpen && <Picker data={data} onEmojiSelect={(emoji: any) => insertEmoji(emoji.native)} />}
    </>
  )
}
