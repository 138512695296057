import { Box, Typography } from '@mui/material'
import { format } from 'date-fns'
import React from 'react'

import { IndividualLessonKarteComponent } from '@/components/common/IndividualLessonKarteComponent'
import { LessonKarteComponent } from '@/components/common/LessonKarteComponent'
import { RestKarteComponent } from '@/components/common/RestKarteComponent'
import { IndividualLessonKarte } from '@/models/IndividualLessonKarte'
import { Karte, karteContentSwitcher } from '@/models/Karte'
import { LessonKarte } from '@/models/LessonKarte'
import { RestKarte } from '@/models/RestKarte'

export const KarteComponent: React.VFC<{ karte: Karte; editable: boolean; afterSubmit: () => void }> = ({
  karte,
  editable,
  afterSubmit,
}) => {
  const lessonKarteElm = (content: LessonKarte) => (
    <LessonKarteComponent lessonKarte={content} editable={editable} afterSubmit={afterSubmit} />
  )
  const individualLessonKarteElm = (content: IndividualLessonKarte) => (
    <IndividualLessonKarteComponent individualLessonKarte={content} editable={editable} afterSubmit={afterSubmit} />
  )
  const restKarteElm = (content: RestKarte) => (
    <RestKarteComponent restKarte={content} editable={editable} afterSubmit={afterSubmit} />
  )
  return (
    <Box>
      <Typography variant="h6">{format(new Date(karte?.lessonDate), 'MM月dd日')}のカルテ</Typography>
      {karteContentSwitcher(karte, lessonKarteElm, individualLessonKarteElm, restKarteElm)}
    </Box>
  )
}
