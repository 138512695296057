import { Box, Button, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { toast } from 'react-toastify'

import { TextEditor } from '@/components/common/TextEditor'
import { axiosContext } from '@/context/axios'
import { IndividualLessonKarte } from '@/models/IndividualLessonKarte'

export const IndividualLessonKarteComponent: React.VFC<{
  individualLessonKarte: IndividualLessonKarte
  editable: boolean
  afterSubmit: () => void
}> = ({ individualLessonKarte, editable, afterSubmit }) => {
  const { axiosInstance } = useContext(axiosContext)
  const [doneComment, setDoneComment] = React.useState(individualLessonKarte.doneComment || '')
  const [achievedComment, setAchievedComment] = React.useState(individualLessonKarte.achievedComment || '')
  const [nextGoalComment, setNextGoalComment] = React.useState(individualLessonKarte.nextGoalComment || '')
  const handleSubmit = async () => {
    try {
      await axiosInstance.post('/api/individual_lesson_karte/comment', {
        id: individualLessonKarte.id,
        doneComment: doneComment,
        achievedComment: achievedComment,
        nextGoalComment: nextGoalComment,
      })
      afterSubmit()
      toast.success('コメントを登録しました')
    } catch (error) {
      toast.error('Server Error')
    }
  }
  return (
    <Box>
      <Box>個別指導</Box>
      <Box sx={{ my: 4 }}>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
        >
          講師からのコメント
        </Typography>
        {editable ? (
          <>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 'bold',
                mt: 3,
              }}
            >
              今日やったこと
            </Typography>
            <TextEditor text={doneComment} onChange={setDoneComment} />
            <Typography
              variant="body1"
              sx={{
                fontWeight: 'bold',
                mt: 3,
              }}
            >
              今日できたこと
            </Typography>
            <TextEditor text={achievedComment} onChange={setAchievedComment} />
            <Typography
              variant="body1"
              sx={{
                fontWeight: 'bold',
                mt: 3,
              }}
            >
              次週の目標
            </Typography>
            <TextEditor text={nextGoalComment} onChange={setNextGoalComment} />
          </>
        ) : (
          <Box component="p">{individualLessonKarte.doneComment ? individualLessonKarte.doneComment : '未返信'}</Box>
        )}
        {editable && (
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{
              my: 2,
            }}
          >
            更新する
          </Button>
        )}
      </Box>
    </Box>
  )
}
