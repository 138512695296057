import { Box, Button, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { toast } from 'react-toastify'

import { TextEditor } from '@/components/common/TextEditor'
import { axiosContext } from '@/context/axios'
import { RestKarte } from '@/models/RestKarte'

export const RestKarteComponent: React.VFC<{
  restKarte: RestKarte
  editable: boolean
  afterSubmit: () => void
}> = ({ restKarte, editable, afterSubmit }) => {
  const { axiosInstance } = useContext(axiosContext)
  const [comment, setComment] = React.useState(restKarte.comment || '')
  const handleSubmit = async () => {
    try {
      await axiosInstance.put('/api/rest_karte/comment', {
        restKarteId: restKarte.id,
        comment: comment,
      })
      afterSubmit()
      toast.success('コメントを登録しました')
    } catch (error) {
      toast.error('Server Error')
    }
  }
  return (
    <Box>
      <Typography variant="h2" sx={{ fontSize: '1rem', my: 2 }}>
        お休み（トレーナー指示）
      </Typography>
      <Box sx={{ my: 4 }}>
        {editable ? (
          <>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 'bold',
                mt: 3,
              }}
            >
              講師からのコメント
            </Typography>
            <TextEditor text={comment} onChange={setComment} />
          </>
        ) : (
          <Box component="p">{restKarte.comment ? restKarte.comment : 'コメントなし'}</Box>
        )}
        {editable && (
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{
              my: 2,
            }}
          >
            更新する
          </Button>
        )}
      </Box>
    </Box>
  )
}
