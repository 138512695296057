export enum Model {
  GPT3_5_TURBO = 'gpt-3.5-turbo',
  GPT4 = 'gpt-4',
  GPT4_TURBO = 'gpt-4-1106-preview',
}
const priceTable = {
  // Modelごとの価格（ドル）
  [Model.GPT3_5_TURBO]: {
    request: 0.0000015,
    response: 0.000002,
  },
  [Model.GPT4]: {
    request: 0.00003,
    response: 0.00006,
  },
  [Model.GPT4_TURBO]: {
    request: 0.00001,
    response: 0.00003,
  },
}

export const estimatePrice = (requestToken: number, responseToken: number, model: Model) => {
  const jpyRate = 150
  return (requestToken * priceTable[model].request + responseToken * priceTable[model].response) * jpyRate
}
